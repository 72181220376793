import React, { FunctionComponent, useState, useEffect } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import Logo from "../../assets/images/casa-logo.svg";
import "./HeaderQuiz.scss";

interface HeaderQuizProps {
  breadcrumbs?: Array<{
    title: string;
    url: string;
  }>;
}

const HeaderQuiz: FunctionComponent<HeaderQuizProps> = props => {
  const { breadcrumbs } = props;
  const [isSticky, setIsSticky] = useState(false);

  const handleSticky = () => {
    setIsSticky(window.pageYOffset > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleSticky, {passive: true});
    return () => window.removeEventListener("scroll", handleSticky);
  }, []);

  return (
    <header className={classNames("header-quiz", isSticky && "header-quiz_sticky")}>
      <div className="container">
        <div className="header-quiz__inner">
          <Link className="header-quiz__logo" to="/">
            <img
              className="header-quiz__logo-image"
              src={Logo}
              alt="CASA by leviathan security group"
              width="141"
              height="26"
            />
          </Link>
          <ul className="header-quiz__nav">
            {breadcrumbs ? (
              breadcrumbs.map((item, index) => (
                <li className="header-quiz__nav-item" key={item.title}>
                  <Link
                    className="header-quiz__nav-link"
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                  {index + 1 !== breadcrumbs.length && (
                    <svg
                      className="header-quiz__nav-arrow"
                      width="9"
                      height="4"
                      viewBox="0 0 9 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.17678 2.17678C8.27441 2.07915 8.27441 1.92086 8.17678 1.82322L6.58579 0.232234C6.48816 0.134603 6.32986 0.134603 6.23223 0.232234C6.1346 0.329865 6.1346 0.488156 6.23223 0.585787L7.64645 2L6.23223 3.41421C6.1346 3.51185 6.1346 3.67014 6.23223 3.76777C6.32986 3.8654 6.48816 3.8654 6.58579 3.76777L8.17678 2.17678ZM-1.87882e-08 2.25L8 2.25L8 1.75L1.87882e-08 1.75L-1.87882e-08 2.25Z"
                        fill="#111111"
                      />
                    </svg>
                  )}
                </li>
              ))
            ) : (
              <>
                <li className="header-quiz__nav-item">
                  <Link
                    className="header-quiz__nav-link"
                    to="/"
                  >
                    Main
                  </Link>
                  <svg
                    className="header-quiz__nav-arrow"
                    width="9"
                    height="4"
                    viewBox="0 0 9 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.17678 2.17678C8.27441 2.07915 8.27441 1.92086 8.17678 1.82322L6.58579 0.232234C6.48816 0.134603 6.32986 0.134603 6.23223 0.232234C6.1346 0.329865 6.1346 0.488156 6.23223 0.585787L7.64645 2L6.23223 3.41421C6.1346 3.51185 6.1346 3.67014 6.23223 3.76777C6.32986 3.8654 6.48816 3.8654 6.58579 3.76777L8.17678 2.17678ZM-1.87882e-08 2.25L8 2.25L8 1.75L1.87882e-08 1.75L-1.87882e-08 2.25Z"
                      fill="#111111"
                    />
                  </svg>
                </li>
                <li className="header-quiz__nav-item">
                  <Link
                    className="header-quiz__nav-link"
                    to="/quiz"
                  >
                    Quiz
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default HeaderQuiz;
