import * as React from "react";
import { Link } from "gatsby";
import HeaderQuiz from "../components/HeaderQuiz";
import FooterQuiz from "../components/FooterQuiz";
import CheckIcon from "../assets/images/check-icon.gif";

const ThanksPage = () => {
  return (
    <>
      <HeaderQuiz />
      <div className="thanks-page">
        <div className="thanks-page__inner">
          <div className="container">
            <div className="thanks-page__image">
              <img
                className="thanks-page__image-i"
                src={CheckIcon}
                alt=""
                width="320"
                height="320"
              />
            </div>
            <h1 className="thanks-page__title">Successfully sent</h1>
            <div className="thanks-page__text">Thank you for your submission. We will be in contact shortly.</div>
            <div className="thanks-page__footer">
              <Link to="/" className="thanks-page__button btn btn_default">Go to start page</Link>
            </div>
          </div>
        </div>
        <div className="thanks-page__bottom">
          <FooterQuiz />
        </div>
      </div>
    </>
  )
}

export default ThanksPage;
