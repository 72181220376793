import React from "react";
import "./FooterQuiz.scss";

const FooterQuiz = () => {
  const date = new Date();

  return (
    <footer className="footer-quiz">
      <div className="container">
        <div className="footer-quiz__inner">
          <div className="footer-quiz__info">
            Have a question? Please, contact us via email&nbsp;
            <a href="mailto:casa@leviathansecurity.com">
						casa@leviathansecurity.com
            </a>
          </div>
          <div className="footer-quiz__copyright">
					All rights reserved &copy; LSG {date.getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterQuiz;
